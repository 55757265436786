.privacyPolicy {
    text-decoration: none;
    color: inherit;

}

.privacyPolicy:hover {
    text-decoration: underline;
    color: #F8F6F0;
}



/* Media Query for mobile phones */
@media only screen and (max-width: 480px) {
    .footerContainer {
        align-items: center;
        background-color: #47688D;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    }

    .footerCompanyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerCompanyName {
        font-size: 36px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 15px;
        letter-spacing: 5.5px;
    }

    .footerCompanyRight {
        font-size: 15px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

/* Media Query for tablets */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .footerContainer {
        align-items: center;
        background-color: #47688D;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    }

    .footerCompanyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerCompanyName {
        font-size: 36px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 15px;
        letter-spacing: 5.5px;
    }

    .footerCompanyRight {
        font-size: 15px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

/* Media Query for laptops/desktops */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .footerContainer {
        align-items: center;
        background-color: #47688D;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    }

    .footerCompanyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerCompanyName {
        font-size: 36px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 15px;
        letter-spacing: 5.5px;
    }

    .footerCompanyRight {
        font-size: 15px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

/* Media Query for laptops/desktops */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .footerContainer {
        align-items: center;
        background-color: #47688D;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    }

    .footerCompanyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerCompanyName {
        font-size: 36px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 15px;
        letter-spacing: 5.5px;
    }

    .footerCompanyRight {
        font-size: 15px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

/* Media Query for larger screens */
@media only screen and (min-width: 1201px) {
    .footerContainer {
        align-items: center;
        background-color: #47688D;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    }

    .footerCompanyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerCompanyName {
        font-size: 36px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 15px;
        letter-spacing: 5.5px;
    }

    .footerCompanyRight {
        font-size: 15px;
        font-weight: bolder;
        color: #F8F6F0;
        padding-top: 10px;
        padding-bottom: 5px;
    }
}