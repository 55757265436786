.descriptionsContainer {
    display: flex;
    flex-direction: column;
    flex: auto;

}

.aboutUs,
.ourVision,
.theSolution,
.contact {
    color: #47688D;
    font-weight: bolder;
}

.desAaboutUs,
.desOurVision,
.desTheSolution,
.desContact {
    color: #47688D;
    font-weight: 500;
    line-height: 130%;
    text-align: justify;
    word-wrap: break-word;
}

.slogan {
    color: #47688D;
    font-weight: bolder;
    word-wrap: break-word;
    flex: auto;
}



/* Media Query for mobile phones */
@media only screen and (max-width: 480px) {
    .descriptionsContainer {
        padding-left: 4%;
        padding-right: 4%;
        padding-bottom: 10%;
        padding-top: 10%;

    }

    .aboutUs,
    .ourVision,
    .theSolution,
    .contact {
        font-size: 25px;
        color: #47688D;
        font-weight: bolder;
        padding-top: 15%;
    }

    .desAaboutUs,
    .desOurVision,
    .desTheSolution,
    .desContact {
        font-size: 22px;
        color: #47688D;
        font-weight: 500;
        padding-top: 20px;
        /* line-height: 23px; */

    }

    .slogan{
        font-size: 30px;
    }
}

/* Media Query for tablets */
@media only screen and (min-width: 481px) and (max-width: 768px)  {
    .descriptionsContainer {
        padding-left: 3%;
        padding-right: 3%;
        padding-bottom: 10%;
        padding-top: 5%;

    }

    .aboutUs,
    .ourVision,
    .theSolution,
    .contact {
        font-size: 25px;
        color: #47688D;
        font-weight: bolder;
        padding-top: 10%;
    }

    .desAaboutUs,
    .desOurVision,
    .desTheSolution,
    .desContact {
        font-size: 22px;
        color: #47688D;
        font-weight: 500;
        padding-top: 20px;

    }

    .slogan{
        font-size: 40px;
    }
}

/* Media Query for laptops/desktops */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .descriptionsContainer {
        padding-left: 3%;
        padding-right: 3%;
        padding-bottom: 10%;
        padding-top: 5%;
    }

    .aboutUs,
    .ourVision,
    .theSolution,
    .contact {
        font-size: 25px;
        color: #47688D;
        font-weight: bolder;
        padding-top: 15%;
    }

    .desAaboutUs,
    .desOurVision,
    .desTheSolution,
    .desContact {
        font-size: 22px;
        color: #47688D;
        font-weight: 500;
        padding-top: 20px;

    }

    .slogan{
        font-size: 35px;
    }
}

/* Media Query for laptops/desktops */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .descriptionsContainer {
        padding-left: 3%;
        padding-right: 3%;
        padding-bottom: 10%;
        padding-top: 5%;

    }

    .aboutUs,
    .ourVision,
    .theSolution,
    .contact {
        font-size: 25px;
        color: #47688D;
        font-weight: bolder;
        padding-top: 10%;
    }

    .desAaboutUs,
    .desOurVision,
    .desTheSolution,
    .desContact {
        font-size: 22px;
        color: #47688D;
        font-weight: 500;
        padding-top: 20px;

    }

    .slogan{
        font-size: 40px;
    }
}

/* Media Query for larger screens */
@media only screen and (min-width: 1201px) {
    .descriptionsContainer {
        padding-left: 20%;
        padding-right: 20%;
        padding-bottom: 7%;
        padding-top: 5%;

    }

    .aboutUs,
    .ourVision,
    .theSolution,
    .contact {
        font-size: 25px;
        color: #47688D;
        font-weight: bolder;
        padding-top: 10%;
    }

    .desAaboutUs,
    .desOurVision,
    .desTheSolution,
    .desContact {
        font-size: 22px;
        color: #47688D;
        font-weight: 500;
        padding-top: 20px;

    }

    .slogan{
        font-size: 40px;
    }
}