.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.logo {
  font-weight: bolder;
  color: #F8F6F0;
  letter-spacing: 5.5px;
  background-color: transparent;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.actionButton {
  background-color: #F8F6F0;
  color:  #47688D;
  cursor: pointer;
  border: none;
  border-radius: 35px;
  font-weight: bold;
}

.actionButton:active {
  background-color: #47688D; 
  color: #F8F6F0; 
  flex: auto;
  font-weight: bold;
}



/* Media Quert for mobile phones  */
@media only screen and (max-width: 480px) {

  .logoContainer {
    flex: auto;
    align-items: center;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 18%;
  }

  .buttonContainer {
    padding-right: 3%;
  }

  .actionButton {
    font-size: 12px;
    padding: 10px 20px;
    margin-left: 20px;
    flex: auto;
  }

  .logo {
    font-size: 35px;
  }

}

/* Media Query for tablets */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .logoContainer {
    flex: auto;
    align-items: center;
    padding-top: 30px;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 18%;
  }

  .buttonContainer {
    padding-right: 20px;
  }

  .actionButton {
    font-size: 12px;
    padding: 15px 25px;
    border-radius: 20px;
    margin-left: 20px;
    flex: auto;
  }

  .logo {
    font-size: 45px;
  }
}

/* Media Query for laptops/desktops */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .logoContainer {
    flex: auto;
    align-items: center;
    padding-top: 30px;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 18%;
  }

  .buttonContainer {
    padding-right: 20px;
  }

  .actionButton {
    font-size: 12px;
    padding: 15px 25px;
    border-radius: 20px;
    margin-left: 20px;
    flex: auto;
  }

  .logo {
    font-size: 45px;
  }
}

/* Media Query for laptops/desktops */
@media only screen and  (min-width: 993px) and (max-width: 1200px) {
  .logoContainer {
    flex: auto;
    align-items: center;
    padding-top: 30px;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 5%;
  }

  .buttonContainer {
    padding-right: 20px;
  }

  .actionButton {
    font-size: 12px;
    padding: 15px 25px;
    border-radius: 20px;
    margin-left: 20px;
    flex: auto;
  }

  .logo {
    font-size: 45px;
  }
}

/* Media Query for larger screens */
@media only screen and (min-width: 1201px) {
  .logoContainer {
    flex: auto;
    align-items: center;
    padding-top: 30px;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 5%;
  }

  .buttonContainer {
    padding-right: 20px;
  }

  .actionButton {
    font-size: 12px;
    padding: 15px 25px;
    border-radius: 20px;
    margin-left: 20px;
    flex: auto;
  }

  .logo {
    font-size: 45px;
  }
}