form {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

label {
    display: block;
    margin-bottom: 10px;
    color: #47688D;
    font-weight: bold;
}

input, select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    
}

button {
    background-color: #47688D;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

textarea {
    width: 100%;
    padding: 8px;
    padding-bottom: 20%;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    resize: vertical;
}

span {
    color: red;
}